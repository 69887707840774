<template>
	<div class="px-3">
		<v-row class="mt-1 mb-2">
			<v-col class="px-0">
				<div class="d-flex align-center">
					<router-link
						:to="{ name: 'mypage-license' }"
						class="text-decoration-none"
					>
						<h3 style="font-weight: 500">신청보드</h3>
					</router-link>
					<span class="mx-1">&#62;</span>
					<h3>신청하기</h3>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" md="6" sm="12" class="pt-0 px-0">
				<v-card class="py-4 px-7">
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<h4>시험 정보</h4>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-3" />
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									타이틀
								</v-list-item-subtitle>
								<v-list-item-title v-if="event && event.title">
									{{ event.title }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									내용
								</v-list-item-subtitle>
								<v-list-item-title v-if="event && event.body">
									<span v-html="event.body" class="body-content"></span>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle>일정</v-list-item-subtitle>
								<v-list-item-title v-if="event && event.eventAt">
									{{ event.eventAt | dateSiFormat }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle>장소</v-list-item-subtitle>
								<v-list-item-title v-if="event && event.location">
									{{ event.location }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									신청 마감일
								</v-list-item-subtitle>
								<v-list-item-title v-if="event">
									<span v-if="event.endRegistrationDate">
										{{ event.endRegistrationDate | _dateFormat }}
									</span>
									<span v-else>-</span>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									모집 인원 (정원)
								</v-list-item-subtitle>
								<v-list-item-title v-if="event && event.maxParticipants">
									<strong>{{ event.presentParticipants }} 명</strong>
									({{ event.maxParticipants }}명)
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
					<v-divider class="my-3" />
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-subtitle class="text-xs">이름</v-list-item-subtitle>
							<v-list-item-title>{{ profile.name }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-subtitle class="text-xs">
								이메일
							</v-list-item-subtitle>
							<v-list-item-title>{{ profile.email }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-subtitle class="text-xs">
								휴대폰번호
							</v-list-item-subtitle>
							<v-list-item-title>{{ profile.phoneNumber }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-card>
			</v-col>
			<v-col
				cols="12"
				md="6"
				sm="12"
				class="pt-0"
				:class="{ 'px-0': isSmAndDown }"
			>
				<v-card class="py-4 px-7">
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<h4>가격 정보</h4>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-3" />
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs text-wrap">
									가격
								</v-list-item-subtitle>
								<v-list-item-title v-if="event" class="text-wrap">
									<div v-if="event.amount > 0">
										<span class="amount primary--text">
											{{ event.amount | commaFormat }} 원
										</span>
										<span :class="{ 'd-block text-sm': isSmAndDown }">
											(교육비 24만원 + 검정료 4만원 + 교재비 1만원)
										</span>
									</div>
									<div v-else>
										<span v-if="!canApply && !canCancel">-</span>
										<span v-else>
											결제완료 (교육신청시 이미 결제 되었습니다.)
										</span>
									</div>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
					<v-card-actions class="px-0 d-flex flex-column">
						<div class="button-wrapper">
							<div v-if="canApply">
								<v-btn
									class="button apply mb-1"
									color="primary"
									:disabled="!canApply"
									@click="applyEvent(event)"
								>
									{{ event.canApply.canApplyDisplayTitle || '신청하기' }}
								</v-btn>
							</div>
							<div v-if="canCancel">
								<v-btn
									class="button apply"
									color="error"
									:disabled="!canCancel"
									@click="cancelEvent(event)"
								>
									{{ event.canCancel.canCancelDisplayTitle }}
								</v-btn>
							</div>
							<div v-if="!canApply && !canCancel">
								<v-btn
									class="button apply"
									color="error"
									:disabled="!canApply"
									v-if="event && event.canApply"
								>
									{{ event.canApply.canApplyDisplayTitle }}
								</v-btn>
							</div>
						</div>
					</v-card-actions>
				</v-card>
				<v-card
					class="py-4 px-7 mt-5"
					v-if="
						event &&
						event.myEventParticipation &&
						event.myEventParticipation.applicationNumber
					"
				>
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<h4>응시 정보</h4>
									<span class="text-xs">
										해당 응시 정보는 시험에 신청 완료시 부여되는 번호입니다
									</span>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-3" />
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs text-wrap">
									응시 번호
								</v-list-item-subtitle>
								<v-list-item-title class="text-wrap">
									<div>
										<span class="amount primary--text">
											{{ event.myEventParticipation.applicationNumber }}
										</span>
									</div>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<h4>교재 및 기출문제</h4>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-3" />
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-title class="text-wrap">
									<ul class="download-link-list">
										<li>
											it마케터 3급 교재
											<a :href="itmTextBookUrl" target="_blank">
												다운로드 받기
											</a>
										</li>
										<li>
											1회 기출문제
											<a :href="itmPastExam1Url" target="_blank">
												다운로드 받기
											</a>
										</li>
										<li>
											2회 기출문제
											<a :href="itmPastExam2Url" target="_blank">
												다운로드 받기
											</a>
										</li>
									</ul>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import store from '@/store'
import { useRouter, getVuetify } from '@core/utils'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import EventService from '@/services/EventService'

export default {
	setup() {
		const $vuetify = getVuetify()
		const { route, router } = useRouter()
		const event = ref()
		const profile = computed(() => store.getters['user/getMeDetail'])
		const eventId = route.value.params.eventId
		const itmTextBookUrl = ref(
			'https://factory-data-history.s3.ap-northeast-2.amazonaws.com/pdf/it%E1%84%86%E1%85%A1%E1%84%8F%E1%85%A6%E1%84%90%E1%85%A5+3%E1%84%80%E1%85%B3%E1%86%B8+%E1%84%80%E1%85%AD%E1%84%8C%E1%85%A2.pdf',
		)
		const itmPastExam1Url = ref(
			'https://factory-data-history.s3.ap-northeast-2.amazonaws.com/pdf/1%E1%84%92%E1%85%AC+%E1%84%80%E1%85%B5%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%86%E1%85%AE%E1%86%AB%E1%84%8C%E1%85%A6.pdf',
		)
		const itmPastExam2Url = ref(
			'https://factory-data-history.s3.ap-northeast-2.amazonaws.com/pdf/2%E1%84%92%E1%85%AC+%E1%84%80%E1%85%B5%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%86%E1%85%AE%E1%86%AB%E1%84%8C%E1%85%A6.pdf',
		)

		const getMeEvent = async () => {
			const [data] = await EventService.getMeEvent(eventId)

			if (!data.visible || !data.isActive) {
				router.go(-1)
				return
			}

			event.value = data
		}
		getMeEvent()

		const canApply = computed(() => {
			return (
				event.value && event.value.canApply && event.value.canApply.canApply
			)
		})

		const canCancel = computed(() => {
			return (
				event.value && event.value.canCancel && event.value.canCancel.canCancel
			)
		})

		const applyEvent = async event => {
			const confirm = await confirmSwal(
				`${event.title} 이벤트를 신청 하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					await EventService.applyEvent(event.id)
					await successSwal({
						html: '신청이 완료되었습니다',
						allowOutsideClick: false,
					})

					router.push({
						name: 'mypage-license',
					})
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '신청하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		const cancelEvent = async event => {
			const confirm = await confirmSwal(
				` ${event.title} 이벤트 신청을 취소 하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					await EventService.cancelEvent(event.id)
					await successSwal({
						html: '신청 취소가 완료되었습니다',
						allowOutsideClick: false,
					})
					router.push({
						name: 'mypage-license',
					})
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '신청을 취소하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		return {
			event,
			profile,
			canApply,
			canCancel,
			isSmAndDown,
			itmTextBookUrl,
			itmPastExam1Url,
			itmPastExam2Url,

			applyEvent,
			cancelEvent,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-list-item {
	padding: 0;

	&__subtitle {
		color: #aaaaaf !important;
	}

	&__title {
		span.body-content {
			::v-deep {
				p {
					margin-bottom: 2px;
				}
			}
		}
	}

	span.amount {
		font-size: 24px;
		font-weight: 900;
		letter-spacing: -0.5px;
	}

	.download-link-list {
		li {
			line-height: 18px;
			margin: 5px 0;

			a {
				color: #0073bb;
				text-decoration: none;
			}
		}
	}
}

.button-wrapper {
	width: 100%;
}

.button {
	display: block;
	width: 100%;
	max-width: 100%;
	min-height: 48px;
	margin-left: 0 !important;
	font-weight: 900;

	::v-deep {
		.v-btn__content {
			height: 100% !important;
		}
	}
}
</style>
